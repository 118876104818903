export const converter = param => {
	var result = [];
	param.forEach(function (a) {
		result.push(a);
		if (Array.isArray(a.dp_users)) {
			result = result.concat(converter(a.dp_users));
		}
	});
	return result;
}

export function numToColor(p) {
	return '#' + ((p >> 8) % 10 * 15 +100).toString(16) + ((p >> 4) % 10 * 15+100).toString(16) + (p % 10 *15 +100).toString(16);
}


export default { converter, numToColor };