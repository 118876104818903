<!--
 * @Author: niweixing
 * @Date: 2021-11-01 14:32:37
 * @LastEditors: niweixing
 * @LastEditTime: 2022-12-14 22:39:18
 * @Description: 
-->
<template>
<ToolTipComponent v-if="fullName" direction="top" :tooltipText="fullName">
<div class="avatar-container d-flex align-center">
  <span class="user" :style="{'min-width': width, height: height}">
    <span class="d-flex justify-center align-center text-center" :style="{backgroundColor:numToColor(!!userId?userId:123)}">
      <span>{{name}}</span>
    </span>
  </span>
  <span class="remove-icon" v-if="isRemove" @click="removeUser">
    <v-icon style="font-size: 12px;color: #fff;">mdi-close</v-icon>
  </span>
</div>
</ToolTipComponent>
<div v-else class="avatar-container d-flex align-center">
  <span class="user" :style="{'min-width': width, height: height}">
    <span class="d-flex justify-center align-center text-center" :style="{backgroundColor:numToColor(!!userId?userId:123)}">
      <span>{{name}}</span>
    </span>
  </span>
  <span class="remove-icon" v-if="isRemove" @click="removeUser">
    <v-icon style="font-size: 12px;color: #fff;">mdi-close</v-icon>
  </span>
</div>
</template>

<script>
import {
  numToColor
} from '@/utils/converter.js';

export default {
  name: 'UserAvatar',
  props: ['name', 'width', 'height', 'isRemove', 'userId','fullName'],
	components: {
    ToolTipComponent:() => import('@/components/okrgoal/shared/ToolTipComponent.vue'),
	},
  methods: {
    numToColor,
    removeUser() {
      this.$emit('picked', this.name)
    }
  }
}
</script>

<style scoped>
.avatar-container {
  position: relative;
}

.user {
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  display: inline-block;
}

.user>span {
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 30px;
  color: rgb(255, 255, 255);
  font-size: 12px;
  position: relative;
  vertical-align: middle;
  line-height: normal;
}

.avatar-container:hover .remove-icon {
  display: inline-block;
}

.remove-icon {
  position: absolute;
  top: -2px;
  right: -3px;
  display: none;
  background: rgba(240, 94, 94);
  width: 15px;
  height: 15px;
  border-radius: 50%;
  padding-right: 0px;
  padding-bottom: 5px;
  text-align: center;
}

.remove-icon i {
  margin-top: -9px;
}

.remove-icon:hover {
  cursor: pointer;
}
</style>
